<template>
  <div v-if="carregandoPagina" class="col-md-12" style="text-align: center;padding-top: 255px;">
    <pm-ProgressSpinner />
  </div>
  <section v-if="!carregandoPagina">
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha" style="vertical-align:middle !important">
          Registrar Alunos <span class="cabecario-turma2" v-if="this.ativa == 1"> Turma Habilitada </span>
        </h2>
      </div>
      <div class="col-md-5" >
        <pm-Button  type="button" @click="this.$router.push({name: 'turmas', params: {pg: this.pg} });"
          icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(0)"
          icon="pi pi-id-card"
          label="DISCIPLINAS" title="DISCIPLINAS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(1)"
          icon="pi pi-id-card"
          label="PROFESSOR" title="PROFESSOR"  class="p-button-sm p-button-info btn-color" style="margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </div>
    <div class="pm-card">

      <div class="row">
        <div class="col-md-6">
          <pm-DataTable :value="alunos" v-model:selection="alunoSelecionado" dataKey="id" class="p-datatable-customers"
            stripedRows scrollable :rowHover="true" selectionMode="multiple" @dblclick="addAluno" :metaKeySelection="false" :resizableColumns="true" scrollHeight="400px" :virtualScrollerOptions="{ itemSize: 46 }">
            <template #header>
              <div class="flex justify-content-center align-items-center" style="padding: 0px">
                <h5 class="m-0">Buscar Alunos</h5>
                <span class="p-input-icon-left col-3" style="padding: 0px">
                  <pm-InputText placeholder="Nome " type="text" style="width:170px;" v-model="aluno" @keyup.enter="buscarAlunos"/>
                </span>
                <span class="p-input-icon-left col-6" style="padding: 0px">
                  <pm-InputText placeholder="Sobrenome" type="text" style="width:200px;" v-model="alunosobrenome" @keyup.enter="buscarAlunosSobrenome"/>
                </span>
              </div>
            </template>


            <pm-Column field="matricula" header="Matrícula" style="flex: 0 0 7.5rem">
              <template #body="alunos">
                <span class="badge bg-secondary">{{alunos.data.matricula}}</span>
              </template>
            </pm-Column>

            <pm-Column field="nome_completo"  header="Nome" style="min-width: 200px"></pm-Column>

          </pm-DataTable>
          <pm-Button v-if="alunoSelecionado" style="float:right;" icon="pi pi-plus" class="mt-2" label="Adicionar à Turma" @click="addAluno"></pm-Button>
        </div>

        <div class="col-md-6">
          <pm-DataTable :value="alunosTurma" @change=verificarSeOAlunoSaiuDaTurma() v-model:selection="alunoSelecionado" dataKey="id" class="p-datatable-customers"
            scrollable scrollHeight="400px" selectionMode="single" @dblclick="deletarAluno" :metaKeySelection="false" :virtualScrollerOptions="{ itemSize: 46 }">
            <template #header>
              <div class="flex justify-content-center align-items-center" style="padding: 7px">
                <h5 class="m-0">Alunos na Turma</h5>
         
              </div>
            </template>


            <pm-Column field="matricula" header="Matricula" style="flex: 0 0 7.5rem">
              <template #body="alunosTurma">
                <span class="badge bg-secondary">{{alunosTurma.data.matricula}}</span>
              </template>
            </pm-Column>

            <pm-Column field="nome_completo" header="Nome" style="min-width: '200px'"></pm-Column>




          </pm-DataTable>
          <pm-Button v-if="alunoSelecionado && alunoSelecionado.status == 'Normal'"  style="float:right;" icon="pi pi-trash" label="Tirar da Turma" class="p-button-danger mt-2" @click="deletarAluno"></pm-Button>
        </div>

      </div>
    </div>

    <va-modal v-model="abrirModalRemover"  hide-default-actions >
      <template #header>
        <h2 style="color: #2196f3;">Remover Aluno na Turma</h2>
      </template>
      <slot>
        <p><b>Deseja remover o aluno(a) <span class="badge badge-secondary2" style="font-size: 15px!important;">{{nome_aluno}}</span> ? Selecione o motivo!</b></p>
        <div class="row" style="margin-bottom:20px;">
          <div class="col-md-12">
            <fieldset>
              <va-radio
                option="option0"
                v-model="radioSelectedOption"
                label="Transferência interna do aluno"
              />
              <br>
              <va-radio
                option="option1"
                v-model="radioSelectedOption"
                label="Saiu da escola (Transferido)"
              />
              <br>
              <va-radio
                option="option2"
                v-model="radioSelectedOption"
                label="Saiu da escola (Evadido)"
              />
              <br>
              <va-radio style="margin-top:10px;"
                option="option3"
                v-model="radioSelectedOption"
                label="Faleceu"
              />
              <br>
              <va-radio style="margin-top:10px;"
                option="option4"
                v-model="radioSelectedOption"
                label="Reclassificado"
              />
              <br>
              <!-- <va-radio style="margin-top:10px;"
                option="option5"
                v-model="radioSelectedOption"
                label="Matrícula Cancelada (CUIDADO: apagará todos os dados do aluno nesta turma)"              
              >  
              </va-radio>-->
            </fieldset>
          </div>

        </div>
        <pm-Button v-if="radioSelectedOption != 'option5'"  type="button"  @click="transferenciaPrimeiraEtapa"
          label="Realizar envio de dados para transferência" title="Realizar ação"  class=" p-button btn-color">
        </pm-Button>
        <pm-Button v-if="radioSelectedOption == 'option5'"  type="button"  @click="confirmarRemocao"
          label="Realizar Remoção" title="Realizar ação"  class=" p-button btn-color">
        </pm-Button>
      </slot>
    </va-modal>

    <va-modal v-model="abrirModalConfirma"  hide-default-actions >
      <template #header>
        <h2 style="color: #2196f3;">Confirmar</h2>
      </template>
      <div class="row" style="margin-top: 15px; margin-bottom: 15px;">
        <div class="col-md-12">
          <div class="form-group col-md-12">
            <label>Para confirmar essa exclusão, digite “confirmar”.</label>
            <input type="text" class="form-control" v-model="textoConfirmacao">
          </div>
        </div>
      </div>
      <pm-Button  label="Cancelar" title="Realizar ação"  class="p-button-danger mt-2"  @click="textoConfirmacao = ''; abrirModalConfirma = false">
      </pm-Button>
      <pm-Button  style="float: right" v-if="textoConfirmacao == 'confirmar'" type="button"  @click="removerAlunoMotivo"
          label="Remover" title="Realizar ação"  class=" p-button btn-color">
      </pm-Button>
    </va-modal>
  </section>
</template>

<script>

import { Aluno } from "@/class/alunos";
import axios from 'axios'
import { defineComponent } from "vue";
import { Turma } from "@/class/turma.js";
import { Transferecia } from "@/class/transferencia.js";

import { SegmentoEscolar } from "@/class/segmentoEscolar.js";

export default defineComponent({
  props: {
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
    turno:{},
    ativa:{},
    //Se for 0, nao faz nada. Se for 1 adicionar apenas disciplinas e se for 2 adicionar apenas alunos
    tipoDeAcao: {
      default: 0,
    },
    erroturmaeditar:{},
    pg:{}
  },
  data() {
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Turmas'},
      ],
      radioSelectedOption: "option0",
      abrirModalRemover:false,
      abrirModalConfirma:false,
      tipo_segmento: null,
      textoConfirmacao: '',
      ano:null,
      aluno:null,
      alunosobrenome:null,
      aluno2:null,
      alunos:[],
      alunosTurma:[],
      alunos_item: [],

      nome_aluno:null,
      aluno_id:null,
      alunoTurmaTransferida:0,
      turmasRelacionadas:[],
      alunoSelecionado: null,
      carregandoPagina:false,
      situacaoAno: 0
    };
  },

  methods: {
    getMotivo() {
        let motivo = "";
        if (this.radioSelectedOption == "option0") {
          motivo = "Transferido Internamente";
        }
        if (this.radioSelectedOption == "option1") {
          motivo = "Transferido Externamente";
        }
        else if (this.radioSelectedOption == "option2") {
          motivo = "Evadido";
        }
        else if (this.radioSelectedOption == "option3") {
          motivo = "Falecido";
        }
        else if (this.radioSelectedOption == "option4") {
          motivo = "Reclassificado";
        }
        else if (this.radioSelectedOption == "option5") {
          motivo = "Matricula Encerrada";        
        }
        return motivo;
    },
    async confirmarRemocao() {
      if (!confirm("Você realmente deseja remover o aluno da turma e todos os seus dados da turma até então? Essa ação é irreversível e o aluno perderá todos os dados da turma até então, inclusive notas e presenças. Tem certeza que deseja continuar?")) {
          return;
      }
      this.textoConfirmacao = '';
      this.abrirModalConfirma = true;
    },

    async removerAlunoMotivo() {
      try {
        this.carregandoPagina = true;

        const novo = {
          motivo: this.getMotivo(),
          turma_id: this.turma_id,
          aluno_id: this.alunoSelecionado.id,
        }
        const data = await Aluno.RemoverAlunoTurma(novo);
        this.alunoSelecionado = false;
        await this.buscarAlunoNaTurma(0);

        this.abrirModalRemover = !this.abrirModalRemover;
        this.$vaToast.init({
            message: "Operação realizada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
        this.abrirModalRemover = false;
        this.abrirModalConfirma = false;
        this.textoConfirmacao = '';
        this.carregandoPagina = false;
      }
      catch(e) {
        this.carregandoPagina = true;
        this.abrirModalRemover = false;
        this.abrirModalConfirma = false;
        this.textoConfirmacao = '';
        this.carregandoPagina = false;
        this.$vaToast.init({
            message: e.response.data.erro,            
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },

    transferenciaPrimeiraEtapa() {
      const dados = {
          aluno_id: this.alunoSelecionado.id,
          turma_inicial: this.turma_id,
          tipo_evento: this.getMotivo()
      }
      this.carregandoPagina = true;
      Transferecia.store(dados).then((resp) => {
      this.$vaToast.init({
            message: resp.data.message,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });

        setTimeout(() => {
          if (window.confirm("Você deseja finalizar a transferência do aluno agora?")) {
            this.$router.push({name: 'gestor/transferencia'});
          }
        }, 600);

      }).catch((err) => {
        this.$vaToast.init({
            message: err.response.data.error,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }).finally(() => {
        this.carregandoPagina = false;
        this.abrirModalRemover = !this.abrirModalRemover;
        this.radioSelectedOption = 'option0';
      });
    },

    irOutraPg(pg){
      if(pg == 0){
       this.$router.push({name: 'turma-add-disciplina', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 1){
       this.$router.push({name: 'turma-add-Professor', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 2){
       this.$router.push({name: 'turma-add-aluno', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
    },

    async realizarTranferenciaAluno() {
      try {
        this.carregandoPagina = true;
        const novo = {
          turma_id: this.turma_id,
          turma_id_nova: this.alunoTurmaTransferida,
          aluno_id: this.alunoSelecionado.id,
        }
        const data = await Aluno.TransferenciaInternaAluno(novo);
        this.alunoTurmaTransferida = 0;

        this.abrirModalRemover = !this.abrirModalRemover;

        this.alunos_item = [];
        this.alunoSelecionado = false;
        await this.buscarAlunoNaTurma(0);
        this.$vaToast.init({
            message: "Aluno transferido com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
        this.carregandoPagina = false;
      }
      catch(e) {
        this.carregandoPagina = false;
        this.$vaToast.init({
            message: "Problema ao transferir aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },

    async buscarAlunos(event) {
      if(this.aluno == null){
        this.buscarAlunoNaTurma(0);
      }

      const data =  await axios.get('/alunos/v2/'+this.aluno+'/'+this.ano);
      this.alunos = [];
      this.alunos = data.data;

      this.alunos_item = [];
      this.buscarAlunoNaTurma(1);
    },
    async buscarAlunosSobrenome(event) {
      if(this.aluno == null){
        this.buscarAlunoNaTurma(0);
      }

      const data =  await axios.get('/alunos/v2/'+this.aluno+'/sobrenome/'+this.alunosobrenome+'/1');
      this.alunos = [];
      this.alunos = data.data;

      this.alunos_item = [];
      this.buscarAlunoNaTurma(1);
    },

    async buscarAlunoNaTurma(n){
      try {
        if(n==0){
          await this.alunosQNaoSaoDaTurma();
        }

        const data = await Turma.obtemAlunosTurma(this.turma_id);
        //pegar os alunos apenas que estejam 'Normal'
        this.alunosTurma = data.data.filter(obj => obj.status == "Normal" || obj.status == "Semestre 1 Encerrado");

        this.alunos_item.push(this.alunos);
        this.alunos_item.push(this.alunosTurma);
      } catch (e) {
        this.carregandoPagina = false;
      }
    },

    async alunosQNaoSaoDaTurma(){
      try {
        const data = await Aluno.obtemTodos2();
        // this.alunos = [];
        this.alunos = data.data;

      } catch (e) {
        this.carregandoPagina = false;
      }
    },

    async identificarTipoSegmento(){
      const data = await SegmentoEscolar.obtemUm(this.etapa_id);
      this.tipo_segmento = data.data.tipo;
    },

    async turmaConfig(){
       const turma = {
        turma_id: this.turma_id,
        etapa_id: this.etapa_id,
        periodo_id: this.periodo_id,
        escola_id: this.escola_id,
      }

      const resp_turma = await Turma.aturma(turma);
      const  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
      ];

      this.items_bread = tt;
    },

    async addAluno(/*payload*/){
      if (this.alunoSelecionado == null) {
        this.$vaToast.init({
          message: "Selecione um aluno",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: 'warning',
          fullWidth: false,
        });
      }else{
        // this.carregandoPagina = true;
        let aluno;
        for (let index = 0; index < this.alunoSelecionado.length; index++) {
          aluno = {
            ano: this.ano,
            turma_id: this.turma_id,
            aluno_id: this.alunoSelecionado[index].id,
          }
          // aluno.aluno_id.push(this.alunoSelecionado[index].id);
          // aluno.ano = this.ano;
          // aluno.turma_id = this.turma_id;
          try{
            const data = await Aluno.addAlunoTurma(aluno);
          }catch(e){
            //console.log(e.response)
            this.$vaToast.init({
              message: e.response.data.erro,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3000,
              color: 'danger',
              fullWidth: false,
            });
          }
        }
        this.alunosQNaoSaoDaTurma()
        this.buscarAlunoNaTurma(1);
        this.alunoSelecionado = false;
        //setTimeout(function() {
        //  this.buscarAlunoNaTurma(0);
        //}, 1000);
        this.carregandoPagina = false;
      }
    },

    async deletarAluno(event){
      if(this.alunoSelecionado == null){
        this.$vaToast.init({
          message: "Selecione um aluno",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: 'warning',
          fullWidth: false,
        });
      }else{
        this.carregandoPagina = true;
        const payload = {
          ano: this.ano,
          aluno_id: this.alunoSelecionado.id,
          turma_id: this.turma_id,
        };
        const dt = await Aluno.deletarAlunoTurmaV2(payload);
        console.log('alo',dt)
        if(dt.data == "Turma Ativa"){
          this.abrirModalRemover = true;
          this.alunos = [];
          await this.buscarAlunoNaTurma(0);
          this.nome_aluno = this.alunoSelecionado.nome_completo;
          await this.buscaTurmasRelacionadas();
        }
        this.carregandoPagina = false;
        await this.buscarAlunoNaTurma(0);
      }
    },

    // moveToTarget(event){
    //   let payload = {
    //     ano: this.ano,
    //     // aluno_id: event.items[0].id,
    //     aluno_id: this.alunos.id,
    //     turma_id: this.turma_id,
    //   };
    //   // for (let index = 0; index < this.alunos.length; index++) {
    //   //   this.alunos[index];
    //   //   payload.aluno_id = this.alunos.id;
    //   // }
    //   // for (const el of this.alunos) {
    //   //   payload.aluno_id = el.id;
    //   // }
    //   this.addAluno(payload);
    // },

    // moveToSource(event){
    //   let payload = {
    //     ano: this.ano,
    //     aluno_id: event.items[0].id,
    //     turma_id: this.turma_id,
    //   };
    //   this.aluno_id = event.items[0].id;
    //   this.deletarAluno(payload,event);
    // },



    async buscaTurmasRelacionadas() {
      const novo = {
        ano: this.ano,
        escola_id:this.escola_id,
        segmento_id: this.etapa_id,
      }

      const data = await Turma.showEscolaSegmentoTurma(novo);
      this.turmasRelacionadas = [];
      for (const el of data.data) {
        //nao mostra a turma que ele atualmente está
        if (el.id == this.turma_id)
          {continue;}
        this.turmasRelacionadas.push(el);
      }
    },
  },
  async beforeMount(){
    try {
      if(this.turma_id != null){
        this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
        this.ano = parseInt(sessionStorage.getItem("anoSelecionado"));

        await this.turmaConfig();
        await this.identificarTipoSegmento();
        await this.buscarAlunoNaTurma(0);
      }else{
        this.$router.push({ name: "turmas"});
      }
    } catch (e) {
      this.carregandoPagina = false;
      console.log(e);
    }
  }
});
</script>

<style>
  #alunturma .p-picklist-source-controls{
   display:none;
 }
 #alunturma .p-picklist-target-controls{
   display:none;
 }

 #alunturma .p-picklist-transfer-buttons .p-button:nth-child(2) {
    display:none;
 }

 #alunturma .p-picklist-transfer-buttons .p-button:nth-child(4) {
    display:none;
 }

 .badge-secondary2 {
    color: #fff;
    background-color: #6c757d;
    font-size: 9px;
 }

 .cabecario-turma2 {
    background-color: rgb(59, 130, 246);
    color: #fff;
    margin-left: 15px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 13px;
    vertical-align:middle !important
}
</style>

<style lang="scss" scoped>
.virtualscroller-demo {
	::v-deep(.p-virtualscroller) {
		height: 200px;
		width: 200px;
		border: 1px solid var(--surface-border);

        .scroll-item {
            background-color: var(--surface-card);
            display: flex;
            align-items: center;
        }

        .custom-scroll-item {
            flex-direction: column;
            align-items: stretch;
        }

        .odd {
            background-color: var(--surface-ground);
        }
	}

    ::v-deep(.p-horizontal-scroll) {
        .p-virtualscroller-content {
            display: flex;
            flex-direction: row;
        }

        .scroll-item {
            writing-mode: vertical-lr;
        }
    }

    ::v-deep(.custom-loading > .p-virtualscroller-loader) {
        display: block;
    }
}
</style>
